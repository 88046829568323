// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top: 3rem;
	
	@include breakpoint(medium) {
		padding-top: 4rem;
	}

	@include breakpoint(giant) {
		padding-top: 5rem;
	}
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	width: 100%;

	.branding {
		display: inline-flex;
		width:100%;
		justify-content:center;
		margin: 1rem 0 0;

		@include breakpoint(large){
			text-align: inherit;
		    display: flex;
		    margin-top: -3rem;
		    z-index: 999;
		    position: fixed;
		    width: auto;
		    justify-content: inherit;
		}

		@include breakpoint(giant){
			margin-top:-4rem;
		}
	}

	.bg-header{
		background: url("/images/bg-header-small.jpg") no-repeat center / cover;
		padding: 2rem;
	    margin: 2rem 0;

	    
	    @include breakpoint(small){
	    	padding: 4rem 0;
	    }

	    @include breakpoint(medium){
	    	margin: 2rem 0 4rem;
	    }

	    @include breakpoint(large){
	    	background: url("/images/bg-header.jpg") no-repeat center / cover;
	    	padding:6rem 0;
	    }


		.claim{
			display:none;
		}

		.Secondclaim{
			display:flex;
			
			@include breakpoint(large){
				transform: translateY(130%);
			}
			.highlight{
				color:$light;
				font-family:$display-font;
				font-weight:700;
				width:100%;
				text-align:center;
				display: inline-flex;
				justify-content:center;
				margin-bottom: 0;
			}
		}
	}

	
}

body{
	&.index{
		.bg-header{
		background: url("/images/bg-header-small.jpg") no-repeat center / cover;
		padding: 2rem;
	    margin: 2rem 0;
	    
	    @include breakpoint(small){
	    	padding: 4rem 0;
	    }

	    @include breakpoint(medium){
	    	margin: 2rem 0 0
	    }

	    @include breakpoint(large){
	    	background: url("/images/bg-header.jpg") no-repeat center / cover;
	    	padding:7rem 0;
	    }

	    @include breakpoint(giant){
	    	padding:14.9rem 0;
	    }

		.claim{
			display:none;

			@include breakpoint(medium){
				display:block;
				text-align:center;
				color:$light;
				font-family:$display-font;
			}

			@include breakpoint(giant){
				transform: translateY(50%);
			}

			.btn{
				margin:0 auto;

				@include breakpoint(large){
					margin: 2.1rem auto;
				}
			}

			.big{
				font-weight:700;
				font-size: rem(20px);
				margin-bottom:0;

				@include breakpoint(large){
					font-weight: 700;
				    font-size: rem(40px);
				    margin-bottom: 0;
				    line-height: 3rem;
				}

				@include breakpoint(giant){
						line-height:5rem;
					}	
			}

			span{
				font-weight:300;
				margin-bottom:0;

				@include breakpoint(large){
					font-size: rem(30px);

				}

				&:after{
					border-bottom: rem(1px) solid $light;
					width:4rem;
					content:"";
					position:absolute;
					top: 52%;
    				right: 46%;

    				@include breakpoint(giant){
    					top: 60%;
    					right: 48%;
    				}	
				}
			}
		}

		.Secondclaim{
			display:flex;

			@include breakpoint(medium){
				display:none;
			}

			.highlight{
				color:$light;
				font-family:$display-font;
				font-weight:700;
				width:100%;
				text-align:center;
				display: inline-flex;
				justify-content:center;
				margin-bottom: 0;
			}
		}
	}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	ul, dl {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}


	.blue{

		@include breakpoint(medium){
			background:$secondary;
			padding: 3.0875rem 0;
		}

			@include breakpoint(giant){
				padding: 7.0875rem 0;
			}

		.teaser{
			display:none;

			@include breakpoint(medium){
				display:flex;
				height:100%;
			}
			li{
				&:before{
					content:"";
				}
				
				.content{
					&.badezimmer{
						
						@include breakpoint(medium){
							background:url("/images/badezimmer.jpg") no-repeat center / cover;
							padding: 11rem 0;
						}

						.btn{
							text-align:inherit;
							align-items:flex-end;
							padding:3rem 1.5rem;
							position: absolute;
    						transform: translateY(37%);

						}
					}
					&.sanitaer{
						
						@include breakpoint(medium){
							background:url("/images/sanitaer.jpg") no-repeat center / cover;
							padding: 11rem 0;
						}
						.btn{
							text-align:inherit;
							align-items:flex-end;
							padding:3rem 3.9rem;
							position: absolute;
    						transform: translateY(37%);

						}
					}
				}
			}


		}
	}

	.firstC{
		
		@include breakpoint(medium){
			padding: 7.0875rem 0;	
		}

		p{
			span{
				font-family:$decoration-font;
				color:$secondary;
				font-size: rem(20px);

			}
		}

		.space{
			    margin-bottom: 5rem;
		}
	}

	.secondC{
		@include breakpoint(medium){
			padding: 7.0875rem 0;
			color:$light;
			background:$secondary;
		}

		img{
			width:auto;
			margin-bottom:1rem;
			@include breakpoint(medium){
				margin-bottom:0;
			}
		}

		.txt{
			@include breakpoint(large){
				margin-top: rem(83px);
			}
		}
	}

	.cont{
		@include breakpoint(large){
			margin-bottom: 5rem;
		}
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
    background: $secondary;

    @include breakpoint(large){
    	padding: 1.8rem 0;
    }


    .fb{
    	width: auto;
	    display: flex;
	    justify-content: center;
	    margin: 0 auto;
    	@include breakpoint(large){
    		display: inline-flex;
		    float: right;
		    margin: -1.8rem 12rem;
		    width:auto;

    	}
		
		@include breakpoint(giant){
			    margin: -1.8rem 18rem;
		}

		@include breakpoint(huge){
			margin: -1.8rem 30rem;
		}
    	
    }


    a.escape.email{
    	transition: 300ms all ease-in-out;
    	&:hover{
    		color:$primary;
    	}
    }
}

.bildunterschrift {
	position: relative;
    top: 12.7rem;
    font-size: 0.7rem;
    color: #ccc;
}

.bildunterschrift2 {
	
	font-size: 0.7rem;
    color: #00061f;
    position: relative;
    top: -1rem;

    @include breakpoint(medium){
			color: #ccc;
			top: 0rem;
		}
}

.bildunterschrift3 {
    font-size: 0.7rem;
    color: #00061f;
}