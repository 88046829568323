// Height for Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	// Off Canvas Styles
	//////////////////////////////
	
	@include breakpoint(large, max) {
		background: $light;
		left: 0;
		overflow-y: auto;
		position: fixed;
		top: rem($menu-bar);
		transform: translateX(-100%);
		transition: .6s;
		width: map-get($breakpoints, tiny) * .8;
		z-index: 895;
	    bottom: 0;
	    max-width: 100%;
	}

	@include breakpoint(large){
		position: fixed;
	    background: #fff;
	    z-index: 9;
	   width:100%;

	}

	.contacts{
		display:none;

		@include breakpoint(large){
			display: flex;
		    white-space: nowrap;
		    padding: 0 44rem;
    		position: relative;
    		bottom: rem(71px);
    		z-index:-1;
		}

		@include breakpoint(giant){
			padding: 0 54rem;
    		position: absolute;
    		bottom: 1.34444rem;
    		z-index: 1;
    		visibility: hidden;
		}

		i{
			font-size:$base-font-size;
			color:$alert;
		}
		ul {
			@include breakpoint(giant){
	    		visibility: visible;
			}
		}
	}
nav{
	width:100%;
	.navi-main {
			display:block;
			width: 100%;
			z-index:10;
			@include breakpoint(large){
			    display: inline-flex;
			    justify-content: center;
			    margin-top:1rem;

			}	
			@include breakpoint(giant){
				display:inline-flex;
	    		justify-content: center;

			}
			li {	
				border-bottom: 1px solid $border;



				@include breakpoint(medium){
					font-size: rem(15px);
				}

				@include breakpoint(large){
					border:none;
					display:inline-flex;
					align-items: center;
				}

				
				@include breakpoint(giant){
					font-size: 1rem;
				}

				a{
					color: $secondary;
					text-decoration: none;
					display: block;
					padding: 2rem 0;
					text-align: center;
					text-transform: uppercase;
					transition: 1s ease;
					font-weight: 700;

					@include breakpoint(medium){
						padding: 2rem 0;

					}

					@include breakpoint(large){
						color:$primary;
						text-transform: none;
						font-weight:300;
						margin: 0 .8rem;
						padding:0;
					}

					@include breakpoint(giant){
						margin: 0 1.5rem;
					}

					&:hover,
					&.active{
						background:$secondary;
						color: $light;

						@include breakpoint(medium){
							background:$secondary;
							color: $light;
						}

						@include breakpoint(large){
							text-decoration:none;
							color:$alert;
							background: transparent;
						}
					}
				}

				&.active{
					a, span{
						color:$light;

						@include breakpoint(large){
							color:$alert;
						}
					}

					&:after{
						color:$secondary;
					}
				}

				&.kontakt {
					@include breakpoint(large){
						border-right: rem(1px) solid #dcdcdc;
						margin: 1rem;
					}
				}
			}

			.hasSub{
				color: $secondary;
				border-bottom: none;
				text-align: center;
				text-transform: uppercase;
				
				@include breakpoint(large){
					color:$primary;
					position:relative;
					text-transform: none;
					font-weight:300;

					&:hover{
							.sub{
								max-height: 170vh;						
								transition: all 1s;
							}
						}
						
				}
				span{
					display: block;
					padding: 2rem 0;
					border-bottom: 1px solid $border;
					transition: 1s ease;
					font-weight: 700;

						
					&:after{
						@extend .fa-solid;
						@extend .fa-angle-down;
						margin-left: 0.25rem;
						color: $primary;
						font-size: rem(14px);
					}

					@include breakpoint(medium){
						padding: 2rem;
					}

					@include breakpoint(large){
						border:none;
						text-transform: none;
						font-weight:300;
					}

					

					&:hover, &.active{
						background:$secondary;
						color: $light;

						@include breakpoint(large){
							background:$light;
							color:$secondary;
						}

					}
				}

			

				.sub {
					li {

						a {
							color: $secondary;
							font-size: rem(13px);
							padding: 2rem 2.2rem;

							@include breakpoint(large){
								padding: 0.5rem 2.2rem;	
								margin: 0;
							}

							&:hover,
							&.active{
								background:$secondary;
								color: $light;

								@include breakpoint(large){
									background:$medium;
									display: block;
								    width: 100%;
								    

								}
								
							}
						}
					}

					@include breakpoint(large){
							border: none;
							position: absolute;
							max-height: 0;
							overflow: hidden;
							left: calc(50% + 1px);
							top: calc(100% + 1px);
							background: $secondary;
							padding: 0;
							text-align: left;
							transition: all 500ms;
							white-space: nowrap;
							z-index: 1111;
							transform: translate3d(-50%, 0, 0);

							li{
								border-top: 1px solid $dark;
								display: block;

								a{
									
									color: $light;
									display:block;

									&:hover,
									&.active{
										color: $primary;
										background:$light;
										display:block;
									}
								}

								&:first-child{
									border-top: none;
								}
							}
					}

				}
			}
		}
	}
}

// Mobile Menu Bar
//////////////////////////////

.mobile-bar {
	align-items: center;
	background: $secondary;
	display: flex;
	height: rem($menu-bar);
	justify-content: space-between;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 894;

	li {
		display: flex;
		flex-grow: 1;
		padding: 0 rem($base-gap);
		color:$light;

		&:first-child {

			i {
				transform: rotate(120deg);
			}
		}

		&:last-child {
            justify-content: flex-end;
        }

        label[for="navi-toggled"] {
			flex-direction: row-reverse;
			font-size: rem(18px);

			&:before {
				@extend .fa-solid;
				@extend .fa-bars;
				margin-right: rem(10px);
			}

			&:after {
				background: rgba($dark,0.8);
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: fixed;
				top: rem($menu-bar);
				transition: opacity .6s, visibility .6s;
				visibility: hidden;
				width: 100%;
				z-index: -1;
			}
		}
	}

	@include breakpoint(large) {
		display: none;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			
			&:before {
				@extend .fa-solid;
				@extend .fa-times;
			}

			&:after {
				opacity: 1;
				pointer-events: auto;
				visibility: visible;
			}
		}

		~ * .page-navi, ~ .page-navi {
			transform: translateX(0);
		}
	}
}

.navi-add{
	
	
	width: 100%;

	@include breakpoint(large){
		width:100%;
		text-align: center;
		
	}

	@include breakpoint(giant){
    	
	}

	@include breakpoint(huge){
		
	}

	li{
		padding: 0.93333rem 0;
		text-align:center;
		display:block;
		border-top:1px solid $border;
		color:$light;

		@include breakpoint(large){
			border-top:none;
			display:inline-block;
			padding: 0.1rem 0.5rem;
			font-size: 1rem;
			font-weight:300;
		}
		
		@include breakpoint(giant){
			font-size: rem(18px);
		}

		&:first-child {
			border-top:none;
		}
		a{
			text-decoration:none;
			color:$light;
			transition: .6s;

			&:hover, &.active{
				font-size: rem(20px);
				font-weight:700;

				@include breakpoint(large){
					font-size: 1rem;
					font-weight:300;
					color:$alert;
				}
			}
			
		}
	}
}