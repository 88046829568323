// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

// @include font-face(
//  $name: "icomoon",
//  $file: "icomoon-webfont",
//  $weight: 400,
//  $style: normal,
//  $type: woff
// );

@include font-face(
    $name: "FontAwesome",
    $file: "fa-regular-400",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "FontAwesome",
    $file: "fa-solid-900",
    $weight: 900,
    $style: normal,
    $type: woff
);

@include font-face("Muli", "mulish-v12-latin-300", 300, normal, $type: woff woff2 ttf svg eot);
@include font-face("Muli", "mulish-v12-latin-regular", 400, normal, $type: woff woff2 ttf svg eot);
@include font-face("Muli", "mulish-v12-latin-700", 700, normal, $type: woff woff2 ttf svg eot);

@include font-face("Open Sans", "open-sans-v34-latin-300", 300, normal, $type: woff woff2 ttf svg eot);
@include font-face("Open Sans", "open-sans-v34-latin-regular", 400, normal, $type: woff woff2 ttf svg eot);
@include font-face("Open Sans", "open-sans-v34-latin-700", 700, normal, $type: woff woff2 ttf svg eot);

